<template>
  <div class="file-create-desktop">

    <div
        v-if="initLoading"
        class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </div>

    <v-card
        v-else
        :disabled="file.loading"
        :loading="file.loading"
        class="file-create-desktop__form">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-pencil"
            title="ویرایش فایل"/>
      </v-card-title>

      <v-card-text>

        <v-row class="my-4">

          <v-col :cols="3">
            <v-text-field
                dense
                v-model="file.phone1"
                hide-details
                outlined
                label="شماره موبایل مالک"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                dense
                v-model="file.phone2"
                hide-details
                outlined
                label="شماره موبایل مالک"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                dense
                v-model="file.phone3"
                hide-details
                outlined
                label="شماره موبایل مالک"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                v-model="file.dateSelection"
                hide-details
                dense
                outlined
                label="تاریخ"
                class="start-date-picker"
            />
            <date-picker
                auto-submit
                v-model="file.dateSelection"
                format="jYYYY/jMM/jDD"
                custom-input=".start-date-picker"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                dense
                v-model="file.owner"
                hide-details
                outlined
                label="نام مالک"
            />
          </v-col>

          <v-col :cols="3">
            <v-select
                dense
                hide-details
                outlined
                label="نوع معامله را انتخاب کنید"
                :items="transactionTypes"
                item-value="id"
                item-text="name"
                v-model="file.transactionType"
            />
          </v-col>

          <v-col :cols="3">
            <v-select
                dense
                hide-details
                outlined
                label="نوع ملک را انتخاب کنید"
                :items="fileTypes"
                item-value="id"
                item-text="name"
                v-model="file.propertyType"
            />
          </v-col>

          <v-col :cols="3">
            <v-combobox
                dense
                hide-details
                outlined
                label="انتخاب محله"
                :items="locations"
                item-value="id"
                item-text="name"
                v-model="file.location"
            />
          </v-col>

          <v-col :cols="3">
            <v-select
                dense
                deletable-chips
                chips
                multiple
                hide-details
                small-chips
                outlined
                label="جهت ملک"
                :items="fileDirections"
                item-value="id"
                item-text="name"
                v-model="file.direction"
            />
          </v-col>

        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <div class="map-container">
              <v-card
                  loader-height="2"
                  :loading="address.loading ? 'main' : false"
                  :disabled="address.loading"
                  elevation="5"
                  :class="['search-box',address.result.length ? 'fill' : '']">
                <v-card-text class="pa-3">

                  <v-text-field
                      dense
                      v-model="address.text"
                      @input="searchLocation"
                      prepend-icon="mdi-map-search-outline"
                      clearable
                      class="pa-0"
                      rounded
                      flat
                      placeholder="جستجو ادرس"
                      color="gray"
                  />

                  <v-list
                      class="search-location-result"
                      dense
                      two-line
                  >
                    <template v-for="(item,index) in address.result">
                      <v-list-item
                          link
                          @click="setMapCenter(item.geo_location.center)"
                          :key="index"
                          dense
                      >

                        <v-list-item-avatar
                            class="ma-0">
                          <v-icon>
                            mdi-map-marker
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-bold text-body-2">
                            {{ item.geo_location.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                      </v-list-item>
                      <v-divider/>
                    </template>


                  </v-list>
                </v-card-text>
              </v-card>
              <div class="map" id='map'></div>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <div>

              <v-row class="mb-4" dense>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.countfloors"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="تعداد طبقه"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.numberofUnits"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="تعداد واحد"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      @focus="calcTotalNumberUnits"
                      v-model="file.totalNumberOfUnits"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="تعداد کل واحد"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.floorNumber"
                      dense
                      outlined
                      hide-details
                      label="طبقه"
                      item-text="name"
                      item-value="id"
                      :items="floorItems"

                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.foundation"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="زیر بنا"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.numberOfSleeps"
                      :items="sleepRoomItems"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details
                      label="تعداد خواب"
                  />
                </v-col>
              </v-row>

              <v-row class="mb-4" dense>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.totalprice"
                      dense
                      outlined
                      hide-details
                      label="قیمت کل / رهن"
                  />
                  {{ convertNumberToWords(file.totalprice) }}
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.pricePerMeter"
                      @focus="calcPriceMeter"
                      dense
                      outlined
                      hide-details
                      label="قیمت متری / اجاره"
                  />
                  {{ convertNumberToWords(file.pricePerMeter) }}
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.kitchenType"
                      :items="kitchenItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      label="اشپزخانه"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.phoneStatus"
                      :items="telephoneItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      label="تلفن"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.wctype"
                      :items="wcItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      label="بهداشتی"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.floorCoveringType"
                      :items="floorCoverItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      label="کفپوش"
                  />
                </v-col>
              </v-row>

              <v-row type="flex" align="center" justify="center" class="mb-4" dense>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.buildingage"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="سن بنا"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.viewtype"
                      :items="namaItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      label="نما"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.typeofDocument"
                      :items="documentItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      label="سند"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.landArea"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="مساحت زمین"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.lengthon"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="طول بر"
                  />
                </v-col>
              </v-row>


            </div>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col
              v-for="(property ,index) in properties"
              :key="index"
              :cols="2">
            <v-checkbox
                v-model="file.properties"
                hide-details
                dense
                :value="property.id"
                :label="property.name"
            />
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col
              v-for="i in 8"
              :key="i"
              cols="3">
            <Uploader
                v-model="file['image'+i]"
                @change="(e)=>{
              addImage(e,i)
            }"
                width="100%"/>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <v-textarea
                dense
                v-model="file.descriptions"
                hide-details
                label="توضیحات"
                height="100"
                outlined
            />
          </v-col>
        </v-row>


        <v-row>

          <v-col :cols="3">
            <v-switch
                flat
                hide-details
                label="فایل مشاور املاک"
                v-model="file.estateFile"
            />

            <v-switch
                flat
                hide-details
                label="فایل اختصاصی"
                v-model="file.special"
            />
            <v-switch
                flat
                hide-details
                label="قیمت تغییر کرده"
                v-model="file.changeConfirmation"
            />
          </v-col>


          <v-col :cols="3">
            <v-btn
                :disabled="!checkButton"
                :loading="file.loading"
                large color="success" @click="submitFile">
              ثبت فایل
            </v-btn>
          </v-col>

          <v-col :cols="6">
            <div class="d-flex align-center justify-space-between w-100">

              <v-btn
                  :loading="fileStatusLoading"
                  color="success"
                  @click="updateFileStatus(1)"
                  v-if="file.filestatus === 2">
                فعال کردن
              </v-btn>
              <v-btn
                  :loading="fileStatusLoading"
                  color="error"
                  @click="updateFileStatus(2)"
                  v-if="file.filestatus === 1">
                غیر فعال کردن
              </v-btn>

              <div style="width: 400px" class="d-flex align-center justify-center mr-6">
                <v-select
                    outlined
                    class="ml-2"
                    :items="fileTransactionStatus.items"
                    hide-details
                    label="وضعیت فایل"
                    v-model="fileTransactionStatus.status"
                    item-text="name"
                    item-value="id"
                />
                <v-btn
                    :loading="fileTransactionStatus.loading"
                    color="primary"
                    @click="updateTransactionStatus"
                >
                  اپدیت کردن وضعیت فایل
                </v-btn>
              </div>


            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import {convertNumberToWords} from "@Newfiling/Services"
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import Uploader from "@/components/Uploader"
import PageTitle from "@/components/PageTitle"
import {
  editFile,
  fetchFile,
  getCoordinate,
  searchLocations,
  updateFileStatus,
  updateFileTransactionStatus
} from "@Newfiling/module-file/src/api";
import _ from 'lodash';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import {AppConfig} from "@Newfiling/Services";
import Vue from "vue";
import moment from "jalali-moment";
import {addCommas, removeCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopFileCreate',

  components: {
    PageTitle,
    Uploader,
    datePicker: VuePersianDatetimePicker
  },

  data() {
    return {
      initLoading: true,
      map: null,
      address: {
        lat: 35.70358951560828,
        lng: 51.3882064819336,
        loading: false,
        text: null,
        result: []
      },
      transactionTypes: [],
      fileTypes: [],
      fileDirections: [
        {
          id: 'N',
          name: 'شمالی'
        },
        {
          id: 'S',
          name: 'جنوبی'
        },
        {
          id: 'E',
          name: 'شرقی'
        },
        {
          id: 'W',
          name: 'غربی'
        },
      ],
      locations: [],
      floorItems: [],
      sleepRoomItems: [],
      kitchenItems: [],
      telephoneItems: [],
      wcItems: [],
      floorCoverItems: [],
      namaItems: [],
      documentItems: [],
      properties: [],
      file: {
        id: null,
        dateSelection: null, //moment().locale('fa').format('jYYYY/jMM/jDD'),
        loading: false,
        phone1: "",
        phone2: "",
        phone3: "",
        owner: "",
        location: "",
        transactionType: "",
        propertyType: "",
        direction: "",
        countfloors: null,
        numberofUnits: null,
        totalNumberOfUnits: null,
        floorNumber: null,
        foundation: null,
        numberOfSleeps: null,
        totalprice: "",
        pricePerMeter: "",
        kitchenType: null,
        phoneStatus: null,
        wctype: null,
        floorCoveringType: null,
        buildingage: null,
        viewtype: null,
        typeofDocument: null,
        landArea: null,
        lengthon: null,
        propertyAddress: null,
        residenceoftheowner: false,
        hastenant: false,
        discharge: false,
        properties: [],
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
        image7: null,
        image8: null,
        descriptions: null,
        zonkenPersonal: false,
        zonkenRealEstat: false,
        sendtonewfile: false,
        filestatus: null,
        estateFile: false,
        special: false,
        changeConfirmation: true,
      },
      fileStatusLoading: false,
      fileTransactionStatus: {
        loading: false,
        status: null,
        items: [
          {
            id: null,
            name: 'هیچ کدام',
          },
          {
            id: 1,
            name: 'اجاره رفته',
          },
          {
            id: 2,
            name: 'فروش رفته',
          },
          {
            id: 3,
            name: 'قیمت تغییر کرده',
          },
          {
            id: 4,
            name: 'منصرف شده',
          },
        ],
      },

    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'ویرایش آگهی',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfigs = (await AppConfig) || {};
      this.locations = appConfigs?.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      });
      this.transactionTypes = appConfigs?.Typeoftransaction.map(item => {
        return {
          id: item.Id,
          name: item.Typeoftransaction,
        }
      })
      this.fileTypes = appConfigs?.PropertyType.map(item => {
        return {
          id: item.Id,
          name: item.PropertyType,
        }
      })
      this.floorItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Floornumber.map(item => {
          return {
            id: item.Id,
            name: item.FloorName,
          }
        })
      ]
      this.sleepRoomItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Numberofsleeps.map(item => {
          return {
            id: item.Id,
            name: item.Numberofsleeps,
          }
        })
      ];
      this.kitchenItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.kitchen.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.telephoneItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Telephonelinenumber.map(item => {
          return {
            id: item.Id,
            name: item.Linestatus,
          }
        })
      ]
      this.wcItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Wclist.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.floorCoverItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.FloorCovering.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.namaItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.ViewType.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.documentItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.typeofDocument.map(item => {
          return {
            id: item.Id,
            name: item.Documentname,
          }
        })
      ]
      this.properties = appConfigs.Features
          .map(item => {
            return {
              id: item.Id,
              name: item.Persianname,
            }
          })
      await this.fetchFile(this.$route.params.id);
      this.init();
    })
  },

  computed: {
    checkButton() {
      return Boolean(
          this.file.phone1 &&
          this.file.owner &&
          this.file.transactionType &&
          this.file.propertyType &&
          this.file.location &&
          this.address.lat &&
          this.address.lng &&
          this.address.text
      );
    }
  },

  methods: {
    convertNumberToWords,
    calcTotalNumberUnits() {
      if (this.file.countfloors && this.file.numberofUnits) {
        this.file.totalNumberOfUnits = parseInt(this.file.countfloors) * parseInt(this.file.numberofUnits)
      }
    },

    calcPriceMeter() {
      if (this.file.transactionType !== 1) return;
      if (this.file.foundation && this.file.totalprice) {
        const price = parseInt(removeCommas(this.file.totalprice)) / parseInt(this.file.foundation);
        this.file.pricePerMeter = this.roundedPrice(addCommas(price.toFixed(0)))
      }
    },

    async fetchFile(id) {
      this.initLoading = true;
      const getDirFile = (file) => {
        return [
          file.north && 'N',
          file.south && 'S',
          file.eastern && 'E',
          file.western && 'W',
        ].filter(item => item);
      }
      try {
        const res = (await fetchFile({
          fileid: id
        }))?.data?.message || {};
        this.file = {
          ...this.file,
          id: res.id,
          dateSelection: moment().locale('fa').format('jYYYY/jMM/jDD'),
          phone1: res.phone1,
          phone2: res.phone2,
          phone3: res.phone3,
          owner: res.ownerName,
          location: this.locations.find(i => i.id === res.locationsid),
          transactionType: res.typeOfTransaction,
          propertyType: res.propertyType,
          direction: getDirFile(res),
          countfloors: res.countfloors,
          numberofUnits: res.numberofUnits,
          totalNumberOfUnits: res.totalNumberOfUnits,
          floorNumber: res.floorNumber,
          foundation: res.foundation,
          numberOfSleeps: res.numberOfSleeps,
          totalprice: addCommas(res.totalprice),
          pricePerMeter: addCommas(res.pricePerMeter),
          kitchenType: res.kitchenType,
          phoneStatus: res.phoneStatus,
          wctype: res.wctype,
          floorCoveringType: res.floorCoveringType,
          buildingage: res.buildingage,
          viewtype: res.viewtype,
          typeofDocument: res.typeofDocument,
          landArea: res.landArea,
          lengthon: res.lengthon,
          residenceoftheowner: res.residenceoftheowner,
          hastenant: res.hastenant,
          discharge: res.discharge,
          properties: res.attributes?.split('-')?.map(item => parseInt(item)).filter(item => item),
          image1: res.image1,
          image2: res.image2,
          image3: res.image3,
          image4: res.image4,
          image5: res.image5,
          image6: res.image6,
          image7: res.image7,
          image8: res.image8,
          imageNewfiling1: res.image1,
          imageNewfiling2: res.image2,
          imageNewfiling3: res.image3,
          imageNewfiling4: res.image4,
          imageNewfiling5: res.image5,
          imageNewfiling6: res.image6,
          imageNewfiling7: res.image7,
          imageNewfiling8: res.image8,
          descriptions: res.descriptions,
          zonkenPersonal: res.zonkenPersonal,
          zonkenRealEstat: res.zonkenRealEstat,
          sendtonewfile: res.sendtonewfile,
          filestatus: res.filestatus,
          estateFile: res.estateFile,
          special: res.special,
        }
        this.address = {
          ...this.address,
          text: res.propertyAddress,
          lng: res.longitude || this.address.lng,
          lat: res.latitude || this.address.lat,
        }
        this.fileTransactionStatus.status = res.fileTransactiontype;
      } catch (e) {
        console.log(e)
      }
      this.initLoading = false;
    },

    async submitFile() {
      this.file.loading = true;
      try {
        const res = await editFile(this.file.id, {
          ...this.file,
          phone1: String(this.file.phone1).trim(),
          phone2: String(this.file.phone2).trim(),
          phone3: String(this.file.phone3).trim(),
          ownerName: this.file.owner,
          locationsid: this.file.location?.id || this.file.location,
          north: this.file.direction.includes('N'),
          south: this.file.direction.includes('S'),
          eastern: this.file.direction.includes('E'),
          western: this.file.direction.includes('W'),
          typeOfTransaction: this.file.transactionType,
          propertyType: this.file.propertyType,
          latitude: this.address.lat,
          longitude: this.address.lng,
          propertyAddress: this.address.text,
          totalprice: this.file.totalprice.replaceAll(',', '') || 0,
          pricePerMeter: this.file.pricePerMeter.replaceAll(',', '') || 0,
          attributes: this.file?.properties?.join('-') || '',
        });
        this.$toast.success('فایل با موفقیت ویرایش شد.');
        res.status2 === true && this.$toast.success(res.message2);
        res.status2 === false && this.$toast.error(res.message2);
        await this.$router.back();
      } catch (e) {
        console.log(e)
      }
      this.file.loading = false;
    },

    addImage(file, index) {
      this.file[`image${index}`] = file.url;
      this.file[`imageNewfiling${index}`] = file.url2;
    },

    init() {
      if (this.map) return;
      const center = [this.address.lat, this.address.lng];
      setTimeout(() => {
        this.map = L.map('map', {
          center,
          zoomControl: false,
          zoom: 15,
        });
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
            .addTo(this.map);

        this.map.attributionControl.setPrefix(false);
        let markerIcon = L.Icon.extend({
          options: {
            iconUrl: require('../../assets/location.png'),
            iconSize: [50, 50],
            iconAnchor: [25, 48],
            shadowSize: [50, 50],
            shadowAnchor: [0, 48],
          }
        });
        let icon = new markerIcon();
        this.marker = new L.marker(center, {
          draggable: 'true',
          icon,
        });
        this.marker.on('dragend', () => {
          let position = this.marker.getLatLng();
          this.marker.setLatLng(position, {
            draggable: 'true'
          }).bindPopup(position).update();
          this.address.lat = position.lat;
          this.address.lng = position.lng;
          this.map.getCenter()
        });
        this.map.addLayer(this.marker);

        // this.map.on('moveend', (e) => {
        //   this.address = {
        //     ...this.address,
        //     ...this.map.getCenter()
        //   }
        // })

      }, 500);

    },

    searchLocation: _.debounce(async function () {
      if (!this.address.text) {
        this.address.result = [];
        return;
      }

      this.address.loading = true;
      try {
        const res = (await searchLocations(this.address.text))?.data?.results || [];

        this.address.result = res.map(item => {
          return {
            ...item,
          }
        });
      } catch (e) {
        console.log(e)
      }
      this.address.loading = false;
    }, 500),

    async setMapCenter(center) {
      this.map.flyTo(new L.LatLng(center.lat, center.lng));
      this.marker.setLatLng(new L.LatLng(center.lat, center.lng));
      this.address.result = [];
      this.address.lat = center.lat;
      this.address.lng = center.lng;
    },

    async updateFileStatus(status) {
      this.fileStatusLoading = true;
      try {
        await updateFileStatus({
          Id: this.file.id,
          Filestatus: status
        });
        const text = `وضعیت فایل تغییر کرد.`
        this.$toast.success(text)
        await this.$router.back();

      } catch (e) {
        console.log(e)
      }
      this.fileStatusLoading = false;
    },

    async updateTransactionStatus() {
      this.fileTransactionStatus.loading = true;
      try {
        await updateFileTransactionStatus({
          Id: this.file.id,
          FileTransactiontype: this.fileTransactionStatus.status,
        });
        const text = `وضعیت فایل تغییر کرد.`
        this.$toast.success(text)
        await this.fetchFile(this.$route.params.id);
      } catch (e) {
        console.log(e)
      }
      this.fileTransactionStatus.loading = false;
    },

    roundedPrice(price) {
      let priceString = String(price); // 333,333,333

      if (priceString) {
        const priceAsArray = priceString.split(','); // [333,333,333]
        console.log({a: priceAsArray.length})
        console.log({priceAsArray})
        switch (priceAsArray.length) {
          case 4: {
            priceString = priceAsArray[0] + priceAsArray[1] + priceAsArray[2] + '000';
            break;
          }
          case 3: {
            priceString = priceAsArray[0] + priceAsArray[1] + '000';
            break;
          }

          case 2: {
            priceString = priceAsArray[0] + '000';
            break;
          }

        }
        return addCommas(priceString);

      }

      return priceString;
    }

  },

  watch: {
    'file.totalprice'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.file.totalprice = result);
    },
    'file.pricePerMeter'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.file.pricePerMeter = result);
    },
  }

}

</script>

<style scoped>

.map-container {
  position: relative;
  z-index: 1;
}

.map-container .marker-icon-custom {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  height: 60px;
  margin: auto;
}


#map {
  width: 100%;
  height: 350px;
  direction: ltr !important;
  border-radius: 10px;
}

#map * {
  direction: ltr !important;
}

a[href="https://leafletjs.com"] {
  display: none !important;
}

span[aria-hidden] {
  display: none !important;
}

.map-container .search-box {
  /*position: absolute;*/
  top: 10px;
  z-index: 9999;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
  height: 60px;
  overflow: hidden;
}

.map-container .search-box.fill {
  height: auto;
}

.search-location-result {
  overflow-y: auto;
  max-height: 250px;
}
</style>
